.dash {
    background-image: linear-gradient(to right, $blue, $orange);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo-dashboard {
        width: 50%;
    }
}