$orange: #1fd0b4;
$wihte-orange: #c5fdf5;
$blue: #1dcdfe;
$gray: #EBF1F5;
$wihte-gray: #dadee0;
$back-ground: #2f455c;
$wihte-back-ground: #4a6581;
$black: rgb(31, 31, 31);
$red: rgb(255, 60, 60);
$wihte-red: rgb(255, 206, 206);
$hover-red: rgb(253, 163, 163);
$green: rgb(4, 148, 4);
$wihte-green: rgb(189, 255, 189);
$hover-green: rgb(121, 247, 121);
$yello: rgb(255, 174, 0);
$wihte-yello: rgb(255, 229, 174);
$hover-yello: rgb(230, 208, 150);
$blue-btn: rgb(5, 45, 175);
$wihte-blue: rgb(189, 209, 253);
$hover-blue: rgb(75, 164, 224);