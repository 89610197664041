.footer {
  background-color: $gray;
  
  .inner-footer {
    text-align: left;
    letter-spacing: .05em;
    font-size: 14px;
    line-height: 24px;
    color: #6e6e6e;
    padding-top: 30px;
    padding-bottom: 60px;
    border-top: 1px solid #dadada ;
  }
}