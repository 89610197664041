.user-edit-form, .add-user-form {
    margin: 0 10px;
    display: flex;
    flex-flow: column;

    h2 {
        color: $orange;
        font-family: 'Nexa', sans-serif;
        margin: 20px 0 30px 0;
        border-bottom: 2px solid $orange;
        width: fit-content;
    }

    .fields {
        display: flex;
        flex-direction: column;

        .input-group {
            display: flex;
            flex-direction: column;

            label {
                color: $back-ground;
                font-size: 18px;
                font-family: 'Nexa', sans-serif;
            }
    
            input {
                outline: #fff;
                border: #fff;
                border-bottom: 1px solid $back-ground;
                margin-bottom: 30px;
                height: 30px;
                font-size: 14px;
                font-family: 'Nexa', sans-serif; 
            }

            select {
                color: $back-ground;
                border: 1px solid $back-ground;
                border-radius: 5px;
                padding: 5px 0;
                outline: #fff;
                margin-bottom: 40px;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;

            .btn-delete {
                background-color: $wihte-red;
                border: 2px solid $red;
                color: $red;
            }

            button {
                width: 40%;
                cursor: pointer;
                color: $orange;
                background-color:$wihte-orange;
                border-radius: 5px;
                border: 2px solid $orange;
                padding: 12px 0 7px 0;
                font-size: 20px;
                font-weight: bold;
                font-family: 'Nexa', sans-serif;
            }
        }
    }
}