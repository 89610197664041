// .contin {
//     display: flex;

//     .mob-logo {
//         display: none;
//         img {
//             width: 200px;
//         }
//     }

//     .form {
//         margin: 18vh 80px 0 80px;
//         background-color: $gray;
//         width: 40%;
//         height: fit-content;
//         padding-top: 20px;
//         border-radius: 20px;
//         box-shadow: 10px 10px 15px $gray;

//         h3 {
//             margin-left: 30px;
//             padding-bottom: 7px;
//             border-bottom: 2px solid $orange;
//             width: fit-content;
//         }

//         .fields {
//             background-color: #fff;
//             width: 100%;
//             padding: 40px 30px;
//             display: flex;
//             flex-direction: column;
//             border-radius: 20px;

//             .input-group {
//                 display: flex;
//                 flex-direction: column;
//                 margin-bottom: 20px;

//                 .label-login {
//                     color: $orange;
//                     font-size: 18px;
//                     font-family: 'Nexa', sans-serif;
//                 }

//                 .input-login {
//                     outline: #fff;
//                     border: #fff;
//                     border-bottom: 1px solid $orange;
//                     margin-bottom: 8px;
//                     height: 30px;
//                     font-size: 14px;
//                     font-family: 'Nexa', sans-serif;
//                 }

//                 .login-error {
//                     color: red;
//                     background-color: rgb(255, 199, 199);
//                     width: fit-content;
//                     padding: 4px 8px;
//                     border-radius: 5px;
//                 }
//             }

//             .btn-login {
//                 cursor: pointer;
//                 color: #fff;
//                 background-color: $orange;
//                 border-radius: 5px;
//                 border: 1px solid $orange;
//                 padding: 7px 0;
//                 font-size: 20px;
//                 font-weight: bold;
//                 font-family: 'Nexa', sans-serif;
//             }
//         }
//     }

//     .logo {
//         position: relative;
//         height: 100vh;
//         min-width: 50%;
//         background-color: $gray;
//         border-radius: 50px 0 0 50px;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         .round {
//             background-color: $gray;
//             position: absolute;
//             width: 100px;
//             height: 60px;
//             top: 0;
//             left: -60px;
//         }

//         .round-2 {
//             background-color: #fff;
//             position: absolute;
//             width: 60px;
//             height: 60px;
//             top: 0;
//             left: -60px;
//             border-radius: 0 100% 0 0;
//         }

//         img {
//             width: 400px;
//         }
//     }
// }

// @media (max-width: 768px) {
//     .contin {
//         flex-direction: column;
//         margin: 0;
//         padding: 0;

//         .mob-logo {
//             display: block;
//             text-align: center;
//             margin: 20px 0;
//         }

//         .form {
//             width: 70%;
//             margin: 0 0 0 15%;
//         }
//     }

//     .logo{
//         display: none !important;
//     }
// }

.login {
  background-image: url("../../images/DashboardBG.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;

  @include responsive-tablet {
    background-image: url("../../images/Dashboard-mobile-BG.webp");
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-bottom: 2px solid #fff;

    @include responsive-tablet {
      flex-direction: column;
    }

    img {
      width: 200px;
    }

    .fields {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      margin-right: 10px;
      margin-bottom: -14px;

      @include responsive-tablet {
        flex-flow: column;
        padding-bottom: 50px;
        align-items: center;
      }

      .input-group {
        display: flex;
        flex-direction: column;

        .forget-pass {
          font-size: 12px;
          font-weight: bold;
          margin-top: 8px;
          margin-left: 15px;
        }
      }

      input {
        background-color: transparent;
        font-weight: bold;
        border: 2px solid#fff;
        border-radius: 20px;
        padding: 8px 6px;
        outline-color: #fff;
      }

      .btn-login {
        color: #fff;
        background-color: #3b64c4;
        padding: 10px 20px;
        border-radius: 20px;
        border: #4471db;
        font-weight: bold;

        @include responsive-tablet {
          width: 60%;
        }

        &:hover {
          background-color: #3b64c4;
        }
      }
    }
  }

  .slogn {
    font-family: 'Metropolis', sans-serif;
    color: #fff;
    font-size: 45px;
    font-weight: 800;
    margin-top: 90px;

    @include responsive-tablet {
      font-size: 25px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    position: absolute;
    bottom: 20px;
    transform: translate(25%);

    

    .icon {
      font-size: 12px;
      width: 20px;
      height: 20px;
      color: #fff;
      background-color: #3b64c4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }

    .name {
      color: #fff;
      font-weight: bold;
      margin-left: 8px;
    }
  }
}

::placeholder {
  color: #fff;
}