.services {
    background-color: $gray;
    padding-top: 50px;
    padding-bottom: 60px;
    
    h1 {
        text-align: center;
        margin-bottom: 40px;
        color: $blue;
        font-size: 30px;
    }

    .service {
        display: flex;
        justify-content: space-between;

        @include responsive-tablet {
            flex-direction: column;
        }

        .card {
            background-color: #ffffff;
            border: 2px solid $blue;
            border-radius: 10px;
            width: 20%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 20px 0;
            color: $blue;

            span {
                color: $blue;
                font-size: 45px;
            }

            @include responsive-tablet {
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 30px;
            }
        }

        .card:hover {
            color: $orange;
            border: 2px solid $orange;
            transform: scale(1.05);
            transition: 0.4s;

            span {
                color: $orange;
                transition: 0.4s;
            }
        }
    }
}