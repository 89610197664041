.privacy {
  letter-spacing: .05em;
  font-size: 14px;
  line-height: 24px;

  .privacy-header {
    padding: 60px 0 30px 0;
  }

  .p-strong {
    display: block;
    padding-bottom: 20px;
  }

  .p {
    padding-bottom: 60px;
  }

  .section {
    padding-bottom: 20px;

    h2 {
      padding-bottom: 10px;
    }

    h3 {
      padding-bottom: 5px;
    }

    p {
      padding-bottom: 30px;
    }
  }

  a {
    color: #4772FA;
    text-decoration: none;
  }

  .last-revision {
    padding: 20px 0 60px 0;
    border-top: 2px solid #ececec;
  }
}