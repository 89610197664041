body {
  font-family: "metropolis", sans-serif;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
}

.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 40px;
}
