.not-found {
    background-color: $back-ground;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    .logo-not-found {
        max-width: 280px;
        margin: 100px 0 30px 0;
    }

    .redirect-contant {
        display: flex;
        flex-direction: column;
        align-items: center;

        .not {
            color: $blue;
            font-family: 'Nexa', sans-serif;
            font-weight: bold;
            font-size: 45px;
    
            .not-span {
                color: $orange;
            }
        }
    
        .not-btn {
            width: 250px;
            cursor: pointer;
            color: #fff;
            background-image: linear-gradient(to right, $blue, $orange);
            border-radius: 5px;
            border: 1px solid $orange;
            padding: 12px 0 7px 0;
            font-size: 20px;
            font-weight: bold;
            font-family: 'Nexa', sans-serif;
            margin-top: 20px;
        }
    }
    
}