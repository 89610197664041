.products {
  padding: 10px 5px;

  .header {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $wihte-gray;
    margin-bottom: 15px;

    .title {
      color: $orange;
      font-family: "Nexa", sans-serif;
      font-weight: bold;
      font-size: 22px;
    }
  }

  .add-btn {
    color: $orange;
    background-color: $wihte-orange;
    border: 2px solid $orange;
    padding: 10px 10px 7px 10px;
    border-radius: 8px;
    margin: 10px 0 10px 0;
    font-family: "Nexa", sans-serif;
    font-weight: bold;
    font-size: 17px;

    .icon {
      margin: 0 5px -2px 0;
      font-size: 16px;
    }
  }
}

.pagenation {
  display: flex;
  justify-content: flex-end;
  margin: 5px 15px;

  .left-arr {
    color: $orange;
    background-color: #fff;
    border: #fff;
    font-size: 25px;
    font-weight: bold;
    margin-right: 15px;
    cursor: pointer;
  }

  .right-arr {
    color: $orange;
    background-color: #fff;
    border: #fff;
    font-size: 25px;
    font-weight: bold;
    cursor: pointer;
  }
}
