.contact {
    padding: 20px 0px 40px 0px;
    background-color: $gray;

    h1 {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 25px;
        color: $orange;
        font-size: 30px;
    }

    .email {
        display: flex;
        justify-content: center;
        text-decoration: none;

        span {
            color: $orange;
            font-size: 30px;
            margin-right: 15px;
        }

        p {
            color: $blue;
            font-weight: bold;
            font-size: 22px;
            padding-top: 2px;

            @include responsive-tablet {
                font-size: 20px;
            }
        }
    }
}
