.product {
  .mt {
    margin-top: 8px;
  }

  .header {
    border-bottom: 1px solid $wihte-gray;
  }

  .back-btn {
    color: $orange;
    background-color: $wihte-orange;
    border: 2px solid $orange;
    padding: 7px 20px 5px 20px;
    border-radius: 8px;
    margin: 10px 20px;
    font-family: "Nexa", sans-serif;
    font-weight: bold;
    font-size: 17px;
  }

  .image {
    width: 50%;
    border-radius: 20px;
  }

  .img {
    width: 100%;
  }

  .content {
    display: flex;

    .info {
      margin-top: 20px;
      margin-left: 10px;
      width: 50%;

      .description {
        width: 80%;
        color: $back-ground;
      }

      .price {
        display: flex;
        gap: 5px;
        align-items: center;

        .was {
          text-decoration: line-through;
        }

        .now {
          font-weight: bold;
          font-size: 22px;
          color: $back-ground;
        }

        .off {
          margin-left: 10px;
          color: $green;
          background-color: $wihte-green;
          font-weight: bold;
          font-size: 14px;
          padding: 5px 6px 1px 6px;
          border-radius: 4px;
        }

        .other {
          font-weight: bold;
          color: $back-ground;
        }
      }

      .title {
        color: $gray;
      }
      
      .edit-btn {
        color: $yello;
        background-color: $wihte-yello;
        border: 2px solid $yello;
        padding: 7px 20px 5px 20px;
        border-radius: 8px;
        margin: 30px 0px;
        font-family: "Nexa", sans-serif;
        font-weight: bold;
        font-size: 17px;
        width: 100px;
        margin-right: 40px;
      }

      .delete-btn {
        color: $red;
        background-color: $wihte-red;
        border: 2px solid $red;
        padding: 7px 20px 5px 20px;
        border-radius: 8px;
        margin: 30px 0px;
        font-family: "Nexa", sans-serif;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
}