.users {
    padding: 10px 5px;

    .add-btn {
        color: $orange;
        background-color: $wihte-orange;
        border: 2px solid $orange;
        padding: 13px 30px 10px 30px;
        border-radius: 8px;
        margin: 10px 0 10px 0;
        font-family: 'Nexa', sans-serif;
        font-weight: bold;
        font-size: 17px;

        .icon {
            margin: 0 5px -2px 0;
            font-size: 16px;
        }
    }
}

.pagenation {
    display: flex;
    justify-content: flex-end;
    margin: 5px 15px;

    .left-arr {
        color: $orange;
        background-color: #fff;
        border: #fff;
        font-size: 25px;
        font-weight: bold;
        margin-right: 15px;
        cursor: pointer;
    }

    .right-arr {
        color: $orange;
        background-color: #fff;
        border: #fff;
        font-size: 25px;
        font-weight: bold;
        cursor: pointer;
    }
}