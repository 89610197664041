.orders {
  padding: 10px 5px;
  
  .order-btn {
    padding: 5px 10px;
    border-radius: 10px;
    font-family: 'Nexa', sans-serif;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
  }

  .acc-btn {
    color: $green;
    background-color: $wihte-green;
    border: 2px solid $green;

    &:hover {
      background-color: $hover-green;
    }
  }

  .rej-btn {
    color: $red;
    background-color: $wihte-red;
    border: 2px solid $red;

    &:hover {
      background-color: $hover-red;
    }
  }

  .delevered-btn {
    color: $blue-btn;
    background-color: $wihte-blue;
    border: 2px solid $blue-btn;

    &:hover {
      background-color: $hover-blue;
    }
  }
}