.about {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;

    @include responsive-tablet {
        flex-direction: column;
        padding-top: 0;
    }

    .content {
        margin-top: 40px;

        .myhero-word {
            width: 240px;
            margin: 0 15px 0 0px;
        }

        .frist-p {
            display: flex;
            align-items: flex-end;
        }

        .second-p {
            margin-left: 120px;
            color: $blue;
        }
        
        @include responsive-tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    p {
        font-size: 36px;
        font-weight: bold;
        color: $back-ground;
        padding: 20px 0 0 0;
        line-height: 1.4;

        @include responsive-tablet {
            width: 100%;
            padding-right: 0;
        }
    }

    .social {
        display: flex;
        font-size: 47px;
        padding: 30px 0px 20px 0px;
        border-bottom: 2px solid $gray;

        .arrow {
            width: 100px;

            .arrow-animat {
                color: $blue;
                animation-duration: 3s;
                animation-name: left-arrow;
                animation-iteration-count: infinite;
                animation-direction: alternate;
            }
        }


        @keyframes left-arrow {
            from {
              margin-left: 40%;;
            }
          
            to {
              margin-left: 80%;
            }
        }


        .face, .insta, .linked, .youtube, .twitter {
            margin-right: 20px;

            :hover {
                transform: scale(.95);
                transition: 0.4s;
            }
        } 
        
        .face {
            margin-left: 40px;
            color: #4267B2;
        }

        .insta {
            color: #C13584;
        }

        .linked {
            color: #0A66C2;
        }

        .youtube {
            color: #FF0000;
        }

        .twitter {
            color: #1DA1F2;
            margin-right: 40px;
        }
    }

    .description {
        margin: 40px 30px 0 0;
        color: $back-ground;
        font-size: 20px;
        max-width: 700px;
        line-height: 1.5;

        .myhero-des {
            margin-right: 10px ;
    
            img {
                width: 110px;
                margin-bottom: -6px;
            }
        }
    }

    .btn-download {
        margin-top: 25px;
        color: $orange;
        background-color: $wihte-orange;
        border: 2px solid $orange;
        position: relative;
        overflow: hidden;
        font-family: Aquire ;

        &::before {
            content: "Contact US";
            position: absolute;
            left: 0;
            top: 0;
            padding-top: 8px;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            transition: 0.4s;
        }

        span {
            display: block;
            transition: 0.4s;
        }

        &:hover::before {
            transform: translateX(0);
            color: $blue;
            background-color: $wihte-orange;
        }

        &:hover span {
            transform: translateX(120%);
        }
    }

    .mockup {
        height: 80vh;
        padding: 0px 10px 0px 20px;

        img {
            width: 100%;
            height: 100%;
        }

        @include responsive-tablet {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px 0 30px 0;

            img {
                width: 30%;
            }
        }
    }
}