.btn {
    background-color: $orange;
    color: #fff;
    padding: 8px 14px;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Nexa';
    border: 1px solid $orange;
    border-radius: 5px;
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
        background-color: $wihte-orange;
    }

    &:active {
        transform: scale(.95);
    }
}