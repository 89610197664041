.navigation-sidebar {
    width: 230px;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $back-ground;
    z-index: 101;
    transition: width 0.3s;

    @include responsive-tablet {
      width: 0;
    }
    
    .mobile-menu {
      position: fixed;
      top: 22px;
      right: 15px;
      cursor: pointer;
      font-size: 30px;
      display: none;
      color: $back-ground;
      background-color: initial;
      border: none;

      @include responsive-tablet {
        display: block;
      }
    }

    .content {
        @include responsive-tablet {
            display: none;
        }

        .side-bar-logo {
            text-align: center;
            margin: 20px 0;
            
            img {
                width: 200px;
                margin: 80px 0 40px 0;
            }
        }

        ul {
            list-style-type: none;
            padding: 0 15px;

            li {
                margin: 10px 0;

                a {
                    text-decoration: none;
                    color: $blue;
                    background-color: $wihte-back-ground;
                    padding: 10px 5px;
                    display: block;
                    border-radius: 5px;
                    font-size: 18px;
                    font-weight: bold;
                        
                    &.active {
                        color: #fff;
                        background-image: linear-gradient(to right, $blue, $orange);
                    }

                    .icon {
                        font-size: 22px;
                        margin: 0 10px -3px 10px;
                    }
                }
            }
        }
        
        .logout-btn {
            background-image: linear-gradient(to right, $blue, $orange);
            color: #fff;
            position: absolute;
            padding: 5px 10px;
            width: 60%;
            font-size: 20px;
            font-weight: bold;
            font-family: 'Nexa', sans-serif;
            border: 1px solid $orange;
            border-radius: 5px;
            margin: 50px 0 0 20%;
            transition: all 0.5s;

            &:hover {
                background-color: $wihte-orange;
            }

            &:active {
                transform: scale(.95);
            }
            
            .logout-icon {
                margin: 0 10px -3px 0;
            }
        }
    }

    &.mobile-sidebar {
        width: 100%;
        box-shadow: 0 0 20px 10px rgba($gray, 0.4);
        .content {
            display: block;
        }
    }
}

.main-content {
    padding-left: 230px;
    @include responsive-tablet {
        padding: 0;
    }
}