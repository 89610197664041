nav {
    background-color: $gray;
    position: sticky;
    top: 0;
    z-index: 10;

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mobile-menu {
            position: fixed;
            top: 18px;
            right: 40px;
            cursor: pointer;
            font-size: 30px;
            display: none;
            color: $orange;
            background-color: initial;
            border: none;
            z-index: 10;
      
            @include responsive-tablet {
              display: block;
            }
        }

        .logo {
            width: 160px;
            margin: 10px 0;
            z-index: 10;
            padding: 10px 0px;
        }
    
        .block {
            display: flex !important;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: $gray;
            transition: height 0.3s;
        }

        .links {
            display: flex;
            list-style: none;
            margin-top: 4px;

            @include responsive-tablet {
                display: none;
                margin-top: 0;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding-top: 100px;
            }

            li {
                margin-left: 25px;

                @include responsive-tablet {
                    margin-bottom: 20px;
                }

                a  {
                    text-decoration: none;
                    color: $blue;
                    font-weight: bold;
                    font-size: 20px;

                    &::after {
                        content: '';
                        display:block;
                        border-bottom: 2px solid $orange;
                        transform: scaleX(0);
                        transition: transform 250ms ease-in-out;
                    }
                    
                    &:hover {
                        color: $orange;

                        &::after {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}


.mob {
    width: 600px;
}